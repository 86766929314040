import Header2 from "../../components/Header2/Header2";
import Bg from "../../images/shapes/shape_title_under_line.svg";
import ProcessTechnology from "../../components/software-company-components/ProcessTechnology/ProcessTechnology";
import Footer from "../../components/software-company-components/Footer/Footer";

import React, { Fragment, useState } from "react";
import About from "../../components/about/about";
import about1 from "../../images/about/about_image_6.webp";
import about2 from "../../images/about/about_image_11.webp";
import about3 from "../../images/about/about_image_10.webp";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import csv1 from "../../images/actual/category-images/cardio/1.png";
import csv2 from "../../images/actual/category-images/cardio/cardio.png";

import s1 from "../../images/actual/category-images/cardio/s1/1.png";
import s2 from "../../images/actual/category-images/cardio/s1/7.png";
import s3 from "../../images/actual/category-images/cardio/s1/11.png";
import s4 from "../../images/actual/category-images/cardio/s1/13.png";

import he1 from "../../images/actual/category-images/cardio/departments/strip/1.png";
import he2 from "../../images/actual/category-images/cardio/departments/strip/2.png";
import he3 from "../../images/actual/category-images/cardio/departments/strip/3.png";
import he4 from "../../images/actual/category-images/cardio/departments/strip/4.png";
import he5 from "../../images/actual/category-images/cardio/departments/strip/5.png";
import he6 from "../../images/actual/category-images/cardio/departments/strip/6.png";
import he7 from "../../images/actual/category-images/cardio/departments/strip/7.png";

import b1 from "../../images/actual/category-images/cardio/departments/box/1.png";
import b2 from "../../images/actual/category-images/cardio/departments/box/2.png";
import b3 from "../../images/actual/category-images/cardio/departments/box/3.png";
import b4 from "../../images/actual/category-images/cardio/departments/box/4.png";
import b5 from "../../images/actual/category-images/cardio/departments/box/5.png";
import b6 from "../../images/actual/category-images/cardio/departments/box/6.png";
import b7 from "../../images/actual/category-images/cardio/departments/box/7.png";

import Project from "../../api/project";
import { Link } from "react-router-dom";

const Category1 = () => {
  const [activeFilter, setActiveFilter] = useState("department1");
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <Fragment>
      <div>
        <Header2 />
        <section className="software_company_hero_section xb-hidden">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="content_wrap">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    Cardiovascular Sciences (CVS)
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="about_section section_space">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center">
            <div className="col-lg-6">
              <img src={csv2} />
            </div>
            <div className="col-lg-6">
              <h2 style={{ paddingLeft: "5%" }}>
                For improving Cardiometabolic health and quality of life in
                hypertensives.
              </h2>
            </div>
          </div>
        </div>
      </section>

      <div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "25px",
              }}
            >
              Cardiovascular Sciences (CVS)
            </h1>
            <div className="col-lg-6">
              <p>
                portfolio includes tablets related to anti-hypertensives, oral
                anti-diabetics, diabetic neuropathic pain, hyperacidity,
                nutraceuticals, and immunity boosters.
              </p>
              <br />
              <img src={csv1} alt="" />
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={s1} alt="" />
              <br />
              <br />
              {/* <img src={s2} alt="" /> */}
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={s3} alt="" />
              <br />
              <br />
              {/* <img src={s4} alt="" /> */}
            </div>
          </div>
        </div>
      </div>

      <section className="portfolio_section section_space bg-light">
        <div className="container">
          <div className="filter_elements_nav">
            <ul className="unordered_list justify-content-center">
              <li
                className={activeFilter === "department1" ? "active" : ""}
                onClick={() => handleFilterClick("department1")}
              >
                <img src={he1} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department2" ? "active" : ""}
                onClick={() => handleFilterClick("department2")}
              >
                <img src={he2} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department3" ? "active" : ""}
                onClick={() => handleFilterClick("department3")}
              >
                <img src={he3} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department4" ? "active" : ""}
                onClick={() => handleFilterClick("department4")}
              >
                <img src={he4} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department5" ? "active" : ""}
                onClick={() => handleFilterClick("department5")}
              >
                <img src={he5} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department6" ? "active" : ""}
                onClick={() => handleFilterClick("department6")}
              >
                <img src={he6} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department7" ? "active" : ""}
                onClick={() => handleFilterClick("department7")}
              >
                <img src={he7} alt="" style={{ width: "105px" }} />
              </li>
            </ul>
          </div>

          <div className="container">
            {activeFilter === "department1" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b1}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    DABITAN - Dabigatran Exexilate 150 mg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department2" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b2}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    BOOST B12 - Vitamin B12 1500 mcg + L-Methylfolate 1 mg +
                    Green Tea 2 mg + chromium 40 mcg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department3" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b3}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    CITAGIN- Sitagliptin 50 mg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department4" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b4}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    CITAGIN-M-Sitagliptin 50 mg + Metformin 500 mg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department5" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b5}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    DAPACIN - Dapagliflozin 10 mg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department6" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b6}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    DAPACIN-M-Dapagliflozin 10 mg + Metformin Hydrochloride 500
                    mg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department7" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={b7}
                        alt=""
                        style={{ border: "10px solid white", height: "250px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    VILDAZYT-Vildagliptin 50 mg
                  </h1>
                  {/* <p>Description or points here.</p> */}
                </div>
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};
export default Category1;
