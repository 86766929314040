import React from "react";
import PartnerSection from "../PartnerSection";
import CountUp from "react-countup";
import sIcon1 from "../../images/icons/icon_head.svg";
import sIcon2 from "../../images/icons/icon_check.svg";
import sIcon3 from "../../images/icons/icon_like.svg";
import sIcon4 from "../../images/icons/icon_dart_board.svg";
import Bg from "../../images/shapes/bg_pattern_1.svg";
import fimg from "../../images/actual/about/section-1-3.png";

const FunFact = [
  {
    title: "10",
    subTitle: "Therapy areas",
    symbol: "+",
    icon: sIcon1,
  },
  {
    title: "200",
    subTitle: "Products",
    symbol: "+",
    icon: sIcon2,
  },
  {
    title: "150",
    subTitle: "Employees",
    symbol: "+",
    icon: sIcon3,
  },
  {
    title: "8",
    subTitle: "Operational States",
    symbol: "+",
    icon: sIcon4,
  },
];

const FeaturesSection = (props) => {
  return (
    <section
      className="client_logo_section section_space"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="section_space pt-0">
          <div className="heading_block text-center">
            <div className="heading_focus_text mb-0">
              <h1>Key Highlights</h1>
            </div>
          </div>
          {/* <PartnerSection /> */}
        </div>

        <div
          className="row funfact_wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-lg-8">
            <div className="row">
              {FunFact.map((funfact, fitem) => (
                <div className="col-md-6" key={fitem}>
                  <div className="funfact_block">
                    <div className="funfact_icon">
                      <img src={funfact.icon} alt="Techco - SVG Icon Head" />
                    </div>
                    <div className="funfact_content">
                      <div className="counter_value">
                        <span className="odometer" data-count="25">
                          <CountUp end={funfact.title} enableScrollSpy />
                        </span>
                        <span>{funfact.symbol}</span>
                      </div>
                      <h3 className="funfact_title mb-0">{funfact.subTitle}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="our_world_employees">
              <div className="image_wrap">
                <img src={fimg} alt="Techco - Employees Guoup" />
              </div>
              <div className="content_wrap">
                <h3 className="title_text mb-0">
                  <b className="d-block">7000+</b> Doctors Prescribe our
                  medicines
                </h3>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
