import React from "react";
// import { Link } from "react-router-dom";
// import Project from "../../../api/project";
import Bg from "../../../images/backgrounds/bg_image_2.webp";
import Bg2 from "../../../images/shapes/shape_bg_1.webp";
import Bg3 from "../../../images/shapes/shape_title_under_line.svg";
import about1 from "../../../images/actual/home/homeabout.png";
import aIcon1 from "../../../images/avatar/avatar_1.webp";
import aIcon2 from "../../../images/avatar/avatar_2.webp";
import aIcon3 from "../../../images/avatar/avatar_3.webp";
import aIcon4 from "../../../images/icons/icon_global.svg";
import aIcon5 from "../../../images/shapes/shape_line.webp";
// import shape1 from "../../../images/shapes/shape_space_2.svg";

const About = (props) => {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };

  return (
    <section
      className="about_and_case_section section_space section_decoration " //bg-dark
      // style={{ backgroundImage: `url(${Bg})` }}
      style={{ backgroundColor: "black" }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-7 order-lg-last">
            <div className="about_image_2">
              <div className="image_wrap">
                <img src={about1} alt="" />
              </div>

              <div
                className="about_funfact_info"
                style={{ backgroundImage: `url(${Bg2})`, maxWidth: "100%" }}
              >
                <div className="customer_count" style={{ marginLeft: "-10px" }}>
                  {/* <ul className="unordered_list">
                    <li>
                      <img src={aIcon1} alt="Customer Avatar" />
                    </li>
                    <li>
                      <img src={aIcon2} alt="Customer Avatar" />
                    </li>
                    <li>
                      <img src={aIcon3} alt="Customer Avatar" />
                    </li>
                    <li>
                      <span>6k+</span>
                    </li>
                  </ul> */}
                  {/* <p className="mb-0">Happy Customer</p> */}
                  <h1 style={{ color: "#3286C7", marginBottom: "-50px" }}>
                    Saanso
                  </h1>
                </div>
                <div
                  className="about_funfact_counter"
                  style={{ paddingTop: "60px", paddingBottom: "40px" }}
                >
                  <div className="funfact_item" style={{ marginLeft: "30px" }}>
                    <div className="counter_value">
                      <span
                        className="odometer"
                        data-count="6"
                        style={{ fontSize: "2rem" }}
                      >
                        10
                      </span>
                      <span style={{ fontSize: "2rem" }}>+</span>
                    </div>
                    <h3 className="funfact_title mb-0">Therapy areas</h3>
                  </div>
                  <div className="funfact_item">
                    <div className="counter_value">
                      <span
                        className="odometer"
                        data-count="100"
                        style={{ fontSize: "2rem" }}
                      >
                        150
                      </span>
                      <span style={{ fontSize: "2rem" }}>+</span>
                    </div>
                    <h3 className="funfact_title mb-0">Employees</h3>
                  </div>
                </div>
                <a className="btn btn-primary" href="/about">
                  <span className="btn_label" data-text="About Us">
                    About US
                  </span>
                  <span className="btn_icon">
                    <i className="fa-solid fa-arrow-up-right"></i>
                  </span>
                </a>
                <div className="icon_globe">
                  <img src={aIcon4} alt="Icon Globe" />
                </div>
              </div>
              <div className="space_line">
                <img src={aIcon5} alt="Shape Line" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about_content">
              <div className="heading_block mb-0 text-white">
                {/* <div
                  className="heading_focus_text has_underline d-inline-flex"
                  style={{ backgroundImage: `url(${Bg3})` }}
                >
                  About Us
                </div> */}
                <h2 className="heading_text">About Us</h2>
                <p className="heading_description mb-0">
                  Founded in 2017, Saanso markets pharmaceutical products in
                  Critical Care, Anesthesia, Pain Management, Cardiovascular,
                  Neuropsychiatric, Respiratory areas. Saanso is a South Indian
                  subsidiary produces affordable generics for domestic markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
