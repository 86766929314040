import React from "react";
import sIcon1 from "../../images/icons/icon_clock.svg";
import sIcon2 from "../../images/icons/icon_dart_board_2.svg";
import sIcon3 from "../../images/icons/icon_target.svg";

const Policy = [
  {
    title: " Core Values",
    subTitle:
      "Innovation, Care, and Quality drive sustainable, ethical healthcare solutions.",
    icon: sIcon1,
  },
  {
    title: " Mission",
    subTitle:
      "Ensure High-Quality, Innovative medicines are Accessible, Affordable & Available Globally.",
    icon: sIcon2,
  },
  {
    title: " Vision",
    subTitle:
      "Aspires to be India’s leading name in pharmaceuticals, health, and wellness by 2030.",
    icon: sIcon3,
  },
];

const PolicySection = (props) => {
  return (
    <section className="policy_section bg-light">
      <div className="container">
        <div className="row">
          {Policy.map((policy, pitem) => (
            <div className="col-lg-4" key={pitem}>
              <div className="iconbox_block">
                <div className="iconbox_icon">
                  <img src={policy.icon} alt="Dollar SVG Icon" />
                </div>
                <div className="iconbox_content">
                  <h3 className="iconbox_title">{policy.title}</h3>
                  <p className="mb-0">{policy.subTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PolicySection;
